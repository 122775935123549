import { defineComponent, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getByUrl } from "@/utils/http";

interface Data {
  solutionDetails: {
    businessTypeId: null;
    id: number;
    imgDetails: string;
    imgIntroduce: string;
    imgPath: string;
    imgTitle: string;
    isHomePage: null;
  } | null;
  successCaseList: Array<{
    id: 1;
    imgDetails: string;
    imgName: null;
    imgPath: string;
    imgTitle: string;
    solutionDetailsId: null;
  }>;
  jumpPage: (name: string, id: number) => void;
}

export default defineComponent({
  name: "SolutionDetails",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { solutionId } = route.params;

    const data: Data = reactive({
      solutionDetails: null,
      successCaseList: [],
      jumpPage: (name, id) => {
        router.push({ name, params: { id } });
      }
    });

    getByUrl("/solution/getSolutionDetails", { solutionId }).then(res => {
      const { solutionList, successCaseList } = res;
      data.solutionDetails = solutionList[0];
      data.successCaseList = successCaseList;
    });

    return {
      ...toRefs(data)
    };
  }
});
