<template>
  <div class="solution-details">
    <div v-if="solutionDetails">
      <h1>{{ solutionDetails.imgTitle }}</h1>
      <div v-html="solutionDetails.imgDetails"></div>
      <h2 v-if="successCaseList.length">行业成功案例</h2>
      <div class="case">
        <div v-for="item in successCaseList" :key="`successCaseList-${item.id}`">
          <img :src="item.imgPath" :alt="item.imgTitle" />
          <div>
            <h3>{{ item.imgTitle }}</h3>
            <p>
              {{ item.imgName }}
            </p>
            <div class="learn-more" @click="jumpPage('CaseDetails', item.id)">了解更多>></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.solution-details {
  background-color: #fdfdfd;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url("~@/assets/image/solution-details-page-bg.png");
  background-size: 100%;
  padding-bottom: 73px;
  padding-top: 60px;
  > div {
    width: $effectiveArea;
    margin: 0 auto;
  }
  h1,
  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
  }
  h1 {
    margin-bottom: 52px;
  }
  h2 {
    margin-top: 44px;
    margin-bottom: 48px;
  }
  p {
    line-height: 26px;
    text-indent: 2em;
    font-size: 14px;
  }
  img {
    display: block;
    width: 922px;
    height: 340px;
    margin: 0 auto;
  }
  .case {
    > div {
      display: flex;
      align-items: center;
      background: #f8f8f8;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 9px 22px 2px rgba(5, 48, 88, 0.07);
      padding: 22px;
      & + div {
        margin-top: 18px;
      }
      img {
        width: 300px;
        height: 260px;
        margin-right: 55px;
      }
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 18px;
        margin-bottom: 36px;
      }
      .learn-more {
        display: inline-block;
        margin-top: 22px;
        width: 136px;
        height: 40px;
        background: #ccc;
        border-radius: 4px;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #ccc;
        display: inline-block;
        vertical-align: middle;
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        transition-property: color;
        transition-duration: 0.5s;
        overflow: hidden;
      }
      .learn-more:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #008fe0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
      }
      .learn-more:hover {
        color: white;
      }
      .learn-more:hover:before {
        transform: scaleX(1);
        transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      }
    }
  }
}
</style>
